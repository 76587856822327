import React, { PropsWithChildren } from 'react';
import { StyledChildrenSection, StyledCloseButton, StyledModalContainer, StyledFormButton } from './Modal.style';
import { ReactComponent as IconClose } from './close.svg';
interface IModalProps {
    isShow?: boolean;
    onClose: () => void;
}

export const Modal: React.FC<PropsWithChildren<IModalProps>> = ({ isShow, onClose, children }) => {

    const onButtonClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        onClose();
    }

    return <>
        <StyledModalContainer data-testid="modal-container">
            <StyledCloseButton onClick={onClose} data-testid="close-button"><IconClose /></StyledCloseButton>
            <StyledChildrenSection>
                {children}
            </StyledChildrenSection>
            <StyledFormButton type="submit" onClick={(e) => onButtonClose(e)} label={'Close'} />
        </StyledModalContainer>
    </>
}
