import React, { useCallback, useEffect, useState } from 'react';
import Api from '../../../../services/Api';
import { CardList } from '../../../CardList/CardList';
import { SvgIcon } from '../../../SvgIcon/SvgIcon';
import { Heading } from '../../../Heading/Heading';
import { ITeammatesStrengths } from '../../../../services/PlayerData';
import { ReactComponent as IconClock } from '../../../../images/icons/clock.svg';

import {
  StyledTeammateSelection,
  StyledHeading,
  StyledButton,
  StyledTeammateStrengthCard,
  StyledFinishButton,
  StyledAddMore,
  StyledTeammateList,
  StyledStrengthSelection,
  StyledSelectionContainer,

  FADE_OUT_TIME,
  StyledOverlayButton,
  StyledButtonContainer,
  StyledMatesStrategyIcon,
  StyledMatesHeading,
  StyledMatesList,
  StyledMatesContainer,
  StyledMatesPage,
  StyledMatesClockContainer,
  StyledMatesClockIcon,
  StyledMatesClockText,
} from './TeammatesStrength.styled';
import { CHAPTER_TRANSITION, STRENGTH_TYPE } from '../../../../constants';
import Content from '../../../../services/Content';
import { IChapter } from '../../../../models/IChapter';
import CohortData from 'services/CohortData';
import { IStrengthCard } from 'models/IStrengthCard';

interface ITeammatesStrengthProps {
  onComplete: () => void;
  scrollTopFn: (delayMs?: number, isSmoothScroll?: boolean) => void;
  chapter: IChapter;
}

interface ITeammate extends Omit<ITeammatesStrengths, 'FirstName' | 'LastName'> {
  index: number;
  name: string;
  recipientID: string;
  isSelected: boolean;
}

const REQUIRED_TEAMMATE_FEEDBACK_COUNT = 3;
const ON_FIELD_STRENGTH_COUNT = 2;
const OFF_FIELD_STRENGTH_COUNT = 2;
const SCROLL_DELAY_WIGGLE_ROOM = 300;
// const TOTAL_STRENGTH_COUNT = ON_FIELD_STRENGTH_COUNT + OFF_FIELD_STRENGTH_COUNT;

export const TeammatesStrength: React.FC<ITeammatesStrengthProps> = ({ onComplete, scrollTopFn, chapter }) => {
  const [showTeamPrompt, setShowTeamPrompt] = useState(true);
  const [showAllTeammates, setShowAllTeammates] = useState(false);
  const [loading, setLoading] = useState(true);

  const [teammates, setTeammates] = useState<ITeammate[]>([]);
  const [nextTeammate, setNextTeammate] = useState<ITeammate | null>(null);
  const [ratedTeammates, setRatedTeammates] = useState<ITeammate[]>([]);

  const isTeammateSelected: boolean = !!nextTeammate && nextTeammate.isSelected;

  const isOnFieldStrengthsActive = (isTeammateSelected && !showAllTeammates && nextTeammate && nextTeammate.StrengthsOnField.length < ON_FIELD_STRENGTH_COUNT) ?? false;
  const isOffFieldStrengthsActive = (isTeammateSelected && nextTeammate && nextTeammate.StrengthsOnField.length === ON_FIELD_STRENGTH_COUNT && nextTeammate.StrengthsOffField.length < OFF_FIELD_STRENGTH_COUNT) ?? false;

  useEffect(() => {
    void (async () => {
      const teamMembers = await CohortData.getTeamMembers();
      const teamateObjs = teamMembers
        .map((teammate, index) => {
          return {
            index,
            name: `${teammate.FirstName} ${teammate.LastName[0]}.`,
            recipientID: teammate.Id,
            StrengthsOnField: [],
            StrengthsOffField: [],
            isSelected: false,
          }
        })

      if (teamateObjs.length > 0) {
        setTeammates(teamateObjs);
        setNextTeammate(teamateObjs[0]);
      } else {
        setShowTeamPrompt(false);
      }
    })().finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    scrollTopFn(FADE_OUT_TIME * 1000 + SCROLL_DELAY_WIGGLE_ROOM, false);
  }, [showAllTeammates])


  const getHeadingForNextRating = useCallback((isTeammatesAllDone: boolean, currentTeamMatesSelection: ITeammate[]) => {
    let heading = '';

    const getNextHeading = (
      _nextTeammate: ITeammate,
      _currentTeamMatesSelection: ITeammate[],
      _ratedTeammates: ITeammate[],
      _isTeammatesAllDone: boolean,
      _loading: boolean,
    ): string => {
      if (_nextTeammate.index === 0) {
        return `Let's start with<br/> ${_nextTeammate.name}`;
      } else if (_currentTeamMatesSelection.length - 1 === _ratedTeammates.length) {
        return 'You have one player left to rate';
      } else {
        return `next up:<br/> ${_nextTeammate.name}`;
      }
    };

    const getHeadingForInitialState = (
      _isTeammatesAllDone: boolean,
      _loading: boolean,
      teammatesCount: number,
    ): string => {
      if (_isTeammatesAllDone) {
        return 'Would you like to rate your other teammates?';
      } else if (!_loading) {
        return teammatesCount > 0 ? "You've rated all your teammates!" : 'Currently, there are no teammates to rate';
      } 
      return '';
    };

    if (nextTeammate) {
      heading = getNextHeading(nextTeammate, currentTeamMatesSelection, ratedTeammates, isTeammatesAllDone, loading);
    } else {
      heading = getHeadingForInitialState(isTeammatesAllDone, loading, teammates.length);
    }

    return heading;
  }, [ratedTeammates, nextTeammate, loading])

  const getOnFieldStrengths = () => {
    return <StyledStrengthSelection
      isActive={isOnFieldStrengthsActive}
      key={`strengths-on-field-${nextTeammate ? nextTeammate.name : ''}`}
    >
      {nextTeammate && <>
        <Heading level="h4" text={`Select two of ${nextTeammate.name}'s ON-field strengths`} />
        <CardList cardComponents={Content.getStrengthCards(STRENGTH_TYPE.OnField).map((card, cardIndex) => {
          const isCardClicked = nextTeammate.StrengthsOnField.includes(card.slug);
          return <StyledTeammateStrengthCard
            key={`strength-card-on-field-${nextTeammate.name}-${cardIndex}`}
            isActive={isCardClicked}
            onClick={() => {
              if (isCardClicked) {
                const index = nextTeammate.StrengthsOnField.indexOf(card.slug);
                nextTeammate.StrengthsOnField.splice(index, 1);
                setNextTeammate({ ...nextTeammate, StrengthsOnField: [...nextTeammate.StrengthsOnField] });
                return;
              }
              if (!isCardClicked && nextTeammate.StrengthsOnField.length < ON_FIELD_STRENGTH_COUNT) {
                setNextTeammate({ ...nextTeammate, StrengthsOnField: [...nextTeammate.StrengthsOnField, card.slug] });
                if (nextTeammate.StrengthsOnField.length === ON_FIELD_STRENGTH_COUNT - 1) scrollTopFn(FADE_OUT_TIME * 1000 + SCROLL_DELAY_WIGGLE_ROOM, false);
              }
            }}
            upperContent={<SvgIcon cmsSrc={card.icon} />}
            lowerContent={<Heading level="h4" text={card.text} />}
          />
        })}
        />
      </>}
    </StyledStrengthSelection>
  };

  const onGetOffFieldStrengthsClick = (_nextTeammate: ITeammate, _isCardClicked: boolean, _card: IStrengthCard) => {
    if (_isCardClicked) {
      const index = _nextTeammate.StrengthsOffField.indexOf(_card.slug);
      _nextTeammate.StrengthsOffField.splice(index, 1);
      setNextTeammate({ ..._nextTeammate, StrengthsOffField: [..._nextTeammate.StrengthsOffField] });
      return;
    }
    if (!_isCardClicked && _nextTeammate.StrengthsOffField.length < OFF_FIELD_STRENGTH_COUNT) {
      if (_nextTeammate.StrengthsOffField.length === OFF_FIELD_STRENGTH_COUNT - 1) {
        // If it's the last strength to rate
        _nextTeammate.StrengthsOffField.push(_card.slug);
        setRatedTeammates([...ratedTeammates, _nextTeammate]);
        setTimeout(() => {
          // If the user wants to rate more teammates, they need to be added manually.
          setNextTeammate(_nextTeammate.index < REQUIRED_TEAMMATE_FEEDBACK_COUNT - 1 ? teammates[_nextTeammate.index + 1] : null);
        }, CHAPTER_TRANSITION.fadingTime * 1000);
        scrollTopFn(FADE_OUT_TIME * 1000 + SCROLL_DELAY_WIGGLE_ROOM, false);

      }
      setNextTeammate({ ..._nextTeammate, StrengthsOffField: [..._nextTeammate.StrengthsOffField, _card.slug] });
    }
  }

  const getOffFieldStrengths = () => {
    return <StyledStrengthSelection
      isActive={isOffFieldStrengthsActive}
      key={`strengths-off-field-${nextTeammate ? nextTeammate.name : ''}`}
    >
      {nextTeammate && <>
        <Heading level="h4" text={`Great! Now select two of ${nextTeammate.name}'s OFF-field strengths`} />
        <CardList cardComponents={Content.getStrengthCards(STRENGTH_TYPE.OffField).map((card, cardIndex) => {
          const isCardClicked = nextTeammate.StrengthsOffField.includes(card.slug);
          return <StyledTeammateStrengthCard
            key={`strength-card-off-field-${nextTeammate.name}-${cardIndex}`}
            isActive={isCardClicked}
            onClick={() => onGetOffFieldStrengthsClick(nextTeammate, isCardClicked, card)}
            upperContent={<SvgIcon cmsSrc={card.icon} />}
            lowerContent={<Heading level="h4" text={card.text} />}
          />
        })}
        />
      </>}
    </StyledStrengthSelection>
  };

  const getRecommendedTeammates = () => {
    const isTeammatesAllDone = ratedTeammates.length < teammates.length;
    const teammateSelection = ratedTeammates.length < REQUIRED_TEAMMATE_FEEDBACK_COUNT ? teammates.slice(0, REQUIRED_TEAMMATE_FEEDBACK_COUNT) : ratedTeammates;
    const heading = getHeadingForNextRating(isTeammatesAllDone, teammateSelection)

    const buttonText = isTeammatesAllDone ? "I'm done" : 'Continue';

    return <StyledTeammateSelection isActive={!isTeammateSelected && !showAllTeammates}>
      <StyledHeading level="h4" primary text={heading} />
      <StyledTeammateList>
        {teammateSelection.map((teammate, index) =>
          <StyledButton
            key={`teammate-recommend-${teammate.name}`}
            label={teammate.name}
            isDisabled={!nextTeammate || index !== nextTeammate.index}
            isCompleted={!nextTeammate || index < nextTeammate.index}
            onClick={() => nextTeammate && setNextTeammate({ ...nextTeammate, isSelected: true })}
          />,
        )}
      </StyledTeammateList>
      {!nextTeammate && <>
        {isTeammatesAllDone && <StyledAddMore onClick={() => setShowAllTeammates(true)}>+ Add more teammates</StyledAddMore>}
        <StyledFinishButton primary label={buttonText} onClick={() => {
          void CohortData.giveTeammatesFeedbacks(ratedTeammates);
          onComplete();
        }} />
      </>}
    </StyledTeammateSelection>
  };

  const generateTeammatesButton = (_teammate: ITeammate) => {
    return <StyledButton
    key={`teammate-all-${_teammate.recipientID}`}
    primary
    label={_teammate.name}
    onClick={() => {
      setNextTeammate({ ..._teammate, isSelected: true });
      setTimeout(() => {
        setShowAllTeammates(false);
      }, CHAPTER_TRANSITION.fadingTime * 1000);
    }}
  />
  }
  

  const getRemainingTeammates = () => {
    return <StyledTeammateSelection isActive={showAllTeammates}>
      <StyledHeading level="h4" primary text='Select a teammate<br/> to rate' />
      {teammates
        .filter(teammate => !ratedTeammates.find(ratedTeammate => teammate.name === ratedTeammate.name))
        .map((teammate) =>generateTeammatesButton(teammate))}
    </StyledTeammateSelection>
  }

  return <>
    {showTeamPrompt ?
      <StyledMatesContainer>
        <StyledMatesPage>
          <StyledMatesStrategyIcon><SvgIcon cmsSrc={chapter.icon} /></StyledMatesStrategyIcon>
          <StyledMatesHeading level="h1" primary text='Let’s focus <br/> on your teammate’s strengths' />
          <StyledMatesList>
            <li>Cheer on your teammates</li>
            <li>Share what you think their <br />strengths are</li>
            <li>It’ll only take a minute</li>
          </StyledMatesList>
          <StyledMatesClockContainer>
            <StyledMatesClockIcon><IconClock /></StyledMatesClockIcon>
            <StyledMatesClockText>2 min</StyledMatesClockText>
          </StyledMatesClockContainer>
          <StyledButtonContainer>
            <StyledOverlayButton label="Rate your mates" isDisabled={!nextTeammate} onClick={() => setShowTeamPrompt(false)} />
          </StyledButtonContainer>
        </StyledMatesPage>
      </StyledMatesContainer>
      :
      <StyledSelectionContainer disableScroll={showTeamPrompt}>
        {getOnFieldStrengths()}
        {getOffFieldStrengths()}
        {!showTeamPrompt && getRecommendedTeammates()}
        {getRemainingTeammates()}
      </StyledSelectionContainer>
    }
  </>
}
